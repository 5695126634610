import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/spotibof.png";
import editor from "../../Assets/Projects/gotek-milipoll.png";
import chatify from "../../Assets/Projects/gotek-projet.png";
import suicide from "../../Assets/Projects/cqld-projet.png";
import bitsOfCode from "../../Assets/Projects/gotek-shipping.png";
import art from "../../Assets/Projects/art-vr.png";
import organisation from "../../Assets/Projects/organisation-travail-equipe.png";
import portefolio from "../../Assets/Projects/portefolio-project.png";
import color from "../../Assets/Projects/color-addict.png";

import { DiAngularSimple } from "react-icons/di";
import { DiMysql } from "react-icons/di";
import { DiPhp } from "react-icons/di";
import { DiMongodb } from "react-icons/di";
import { FaVuejs } from "react-icons/fa";
import { DiNodejsSmall } from "react-icons/di";
import { useTranslation } from "react-i18next";

function Projects() {
  const { t } = useTranslation();
  const [showEnterpriseProjects, setShowEnterpriseProjects] = useState(true);

  const devIconsFirst = [
    <DiAngularSimple key="angular" />,
    <DiPhp key="php" />,
    <DiMysql key="mysql" />,
  ];

  const devIconsSecond = [
    <FaVuejs key="FaVuejs" />,
    <DiNodejsSmall key="DiNodejsSmall" />,
    <DiMongodb key="mongodb" />,
  ];

  const devIconsThree = [
    <DiAngularSimple key="angular" />,
    <DiNodejsSmall key="DiNodejsSmall" />,
    <DiMongodb key="mongodb" />,
  ];

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          {t("projects.recentProjects")}{" "}
          <strong className="purple">{t("projects.projects")}</strong>
        </h1>

        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <Col md={12} style={{ textAlign: "center" }}>
            <Button
              size="sm"
              variant={showEnterpriseProjects ? "primary" : "outline-primary"}
              className={
                showEnterpriseProjects ? "test-button selected" : "test-button"
              }
              style={{
                borderRight: "none",
                borderTopRightRadius: "0",
                borderBottomRightRadius: "0",
                marginLeft: "0",
                marginRight: "0",
              }}
              onClick={() => setShowEnterpriseProjects(true)}
            >
              {t("projects.academic")}
            </Button>
            <Button
              size="sm"
              className={
                !showEnterpriseProjects ? "test-button selected" : "test-button"
              }
              variant={!showEnterpriseProjects ? "primary" : "outline-primary"}
              style={{
                borderLeft: "none",
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
                marginLeft: "0",
                marginRight: "0",
              }}
              onClick={() => setShowEnterpriseProjects(false)}
            >
              {t("projects.enterprise")}
            </Button>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {showEnterpriseProjects ? (
            <>
              <Col md={4} className="project-card">
                <ProjectCard
                  imgPath={suicide}
                  isBlog={false}
                  title="CQLD"
                  description={t("projects.cqldDescription")}
                  descriptionBack={t("projects.cqldDescriptionBack")}
                  ghLink="https://github.com/soumyajit4419/Chatify"
                  demoLink="https://chatify-49.web.app/"
                  devIcons={devIconsSecond}
                />
              </Col>
              <Col md={4} className="project-card">
                <ProjectCard
                  imgPath={leaf}
                  isBlog={false}
                  title={t("projects.musicInfo")}
                  description={t("projects.musicInfoDescription")}
                  descriptionBack={t("projects.musicInfoDescriptionBack")}
                  ghLink="https://github.com/soumyajit4419/Chatify"
                  demoLink="https://chatify-49.web.app/"
                  devIcons={devIconsSecond}
                />
              </Col>
              <Col md={4} className="project-card">
                <ProjectCard
                  imgPath={art}
                  isBlog={false}
                  title="ART VR"
                  description={t("projects.artVrDescription")}
                  descriptionBack={t("projects.artVrDescriptionBack")}
                  ghLink="https://github.com/soumyajit4419/Chatify"
                  demoLink="https://chatify-49.web.app/"
                  devIcons={devIconsSecond}
                />
              </Col>
              <Col md={4} className="project-card">
                <ProjectCard
                  imgPath={color}
                  isBlog={false}
                  title={t("projects.colorAddict")}
                  description={t("projects.colorAddictDescription")}
                  descriptionBack={t("projects.colorAddictDescriptionBack")}
                  ghLink="https://github.com/soumyajit4419/Chatify"
                  demoLink="https://chatify-49.web.app/"
                  devIcons={devIconsSecond}
                />
              </Col>

              <Col md={4} className="project-card">
                <ProjectCard
                  imgPath={organisation}
                  isBlog={false}
                  title={t("projects.informaTips")}
                  description={t("projects.informaTipsDescription")}
                  descriptionBack={t("projects.informaTipsDescriptionBack")}
                  ghLink="https://github.com/soumyajit4419/Chatify"
                  demoLink="https://chatify-49.web.app/"
                  devIcons={devIconsSecond}
                />
              </Col>
              <Col md={4} className="project-card">
                <ProjectCard
                  imgPath={portefolio}
                  isBlog={false}
                  title="Portefolio"
                  description={t("projects.portfolioDescription")}
                  descriptionBack={t("projects.portfolioDescriptionBack")}
                  ghLink="https://github.com/soumyajit4419/Chatify"
                  demoLink="https://chatify-49.web.app/"
                  devIcons={devIconsSecond}
                />
              </Col>
            </>
          ) : (
            <>
              <Col md={4} className="project-card">
                <ProjectCard
                  imgPath={editor}
                  isBlog={false}
                  title={t("projects.productInfo")}
                  description={t("projects.productInfoDescription")}
                  descriptionBack={t("projects.productInfoDescriptionBack")}
                  ghLink="https://github.com/soumyajit4419/Chatify"
                  demoLink="https://chatify-49.web.app/"
                  devIcons={devIconsFirst}
                />
              </Col>
              <Col md={4} className="project-card">
                <ProjectCard
                  imgPath={bitsOfCode}
                  isBlog={false}
                  title={t("projects.logisticPlatformRefactoring")}
                  description={t(
                    "projects.logisticPlatformRefactoringDescription"
                  )}
                  descriptionBack={t(
                    "projects.logisticPlatformRefactoringDescriptionBack"
                  )}
                  ghLink="https://github.com/soumyajit4419/Chatify"
                  demoLink="https://chatify-49.web.app/"
                  devIcons={devIconsThree}
                />
              </Col>
              <Col md={4} className="project-card">
                <ProjectCard
                  imgPath={chatify}
                  isBlog={false}
                  title={t("projects.gpsTagsManagement")}
                  description={t("projects.gpsTagsManagementDescription")}
                  descriptionBack={t(
                    "projects.gpsTagsManagementDescriptionBack"
                  )}
                  ghLink="https://github.com/soumyajit4419/Chatify"
                  demoLink="https://chatify-49.web.app/"
                  devIcons={devIconsFirst}
                />
              </Col>
            </>
          )}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
