// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "../Assets/traduction/en.json";
import frTranslation from "../Assets/traduction/fr.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    fr: {
      translation: frTranslation,
    },
  },
  lng: "en", // Langue par défaut
  fallbackLng: "en", // Langue de secours en cas de traduction manquante
  interpolation: {
    escapeValue: false, // Ne pas échapper les valeurs HTML
  },
});

export default i18n;
