import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@mui/icons-material/School";
import StarIcon from "@mui/icons-material/Star";
import AirlineStopsIcon from "@mui/icons-material/AirlineStops";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import myImg from "../../Assets/gotek7-logoo.png";
import logoCoubertin from "../../Assets/coubertin-logo.png";
import logoDFDS from "../../Assets/dfds-logo.png";
import logoIUT from "../../Assets/iut-logo.png";
import { useTranslation } from "react-i18next";

function Parcours() {
  const { t } = useTranslation();

  return (
    <VerticalTimeline>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: "#2c666d00", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid  #1f4a4e" }}
        date="2021 - 2024"
        iconStyle={{ background: "#153336", color: "#fff" }}
        icon={<SchoolIcon />}
      >
        <h3 className="vertical-timeline-element-title">
          {t("parcours.alternanceDevelopper")}
        </h3>
        <h5 className="vertical-timeline-element-subtitle">GoTEK7 - Calais</h5>
        <p>{t("parcours.platformRefactoring")}</p>
        <div className="logo-badges">
          {" "}
          <div style={{ maxWidth: "16%", paddingTop: 20 }}>
            <img
              src={myImg}
              alt="alternance"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", paddingTop: 10 }}
          >
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">Angular</span>
            </div>
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">Node.js</span>
            </div>
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">MongoDB</span>
            </div>
          </div>
        </div>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: "#2c666d00", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid  #1f4a4e" }}
        date="2021 - 2024"
        iconStyle={{ background: "#153336", color: "#fff" }}
        icon={<SchoolIcon />}
      >
        <h3 className="vertical-timeline-element-title">
          {t("parcours.computerScienceStudent")}
        </h3>
        <h5 className="vertical-timeline-element-subtitle">IUT de Calais</h5>
        <p>{t("parcours.learningComputerScience")}</p>
        <div className="logo-badges">
          {" "}
          <div style={{ maxWidth: "16%", paddingTop: 20 }}>
            <img
              src={logoIUT}
              alt="logo-iut"
              style={{ width: "50%", height: "auto" }}
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", paddingTop: 10 }}
          >
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">React</span>
            </div>
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">.Net</span>
            </div>
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">Teamwork</span>
            </div>
          </div>
        </div>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: "#2c666d00", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid  #1f4a4e" }}
        date="Jul 2023 - Sept 2023"
        iconStyle={{ background: "#153336", color: "#fff" }}
        icon={<CardTravelIcon />}
      >
        <h3 className="vertical-timeline-element-title">
          {t("parcours.customerServiceAgent")}
        </h3>
        <h5 className="vertical-timeline-element-subtitle">DFDS - Calais</h5>
        <p>{t("parcours.interactingWithForeignCustomers")}</p>
        <div className="logo-badges-dfds">
          {" "}
          <div style={{ maxWidth: "16%", paddingTop: 20 }}>
            <img
              src={logoDFDS}
              alt="logo-dfds"
              style={{ width: "60%", height: "auto" }}
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", paddingTop: 10 }}
          >
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">
                {t("parcours.clientRelation")}
              </span>
            </div>
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">
                {" "}
                {t("parcours.TimeManagement")}
              </span>
            </div>
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">
                {" "}
                {t("parcours.CustomerSatisfaction")}
              </span>
            </div>
          </div>
        </div>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: "#2c666d00", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid  #1f4a4e" }}
        date="Apr 2023 - June 2023"
        iconStyle={{ background: "#153336", color: "#fff" }}
        icon={<AirlineStopsIcon />}
      >
        <h3 className="vertical-timeline-element-title">
          {t("parcours.webDeveloperInternship")}
        </h3>
        <h5 className="vertical-timeline-element-subtitle">GoTEK7 - Calais</h5>
        <p>{t("parcours.developingRealTimeGPSApplication")}</p>
        <div className="logo-badges">
          {" "}
          <div style={{ maxWidth: "16%", paddingTop: 20 }}>
            <img
              src={myImg}
              alt="logo-gotek"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", paddingTop: 10 }}
          >
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">Angular</span>
            </div>
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">PHP</span>
            </div>
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">SQL</span>
            </div>
          </div>
        </div>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: "#2c666d00", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid  #1f4a4e" }}
        date="April 2013"
        iconStyle={{ background: "#153336", color: "#fff" }}
        icon={<SchoolIcon />}
      >
        <h3 className="vertical-timeline-element-title">
          {t("parcours.highSchoolDiploma")}
        </h3>
        <h5 className="vertical-timeline-element-subtitle">
          {t("parcours.coubertinHighSchool")}
        </h5>
        <p>{t("parcours.computerScienceAndMathematics")}</p>
        <div className="logo-badges-coub">
          {" "}
          <div style={{ maxWidth: "16%", paddingTop: 20 }}>
            <img
              src={logoCoubertin}
              alt="logo-coubertin"
              style={{ width: "50%", height: "auto" }}
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", paddingTop: 10 }}
          >
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">Python</span>
            </div>
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">SQL</span>
            </div>
            <div style={{ marginRight: 10 }}>
              <span className="span-badges">Linux</span>
            </div>
          </div>
        </div>
      </VerticalTimelineElement>

      <div className="no-box">
        {" "}
        <VerticalTimelineElement
          iconStyle={{ background: "#006f43 ", color: "#fff" }}
          icon={<StarIcon />}
        />
      </div>
    </VerticalTimeline>
  );
}

export default Parcours;
