import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import i18n from "./components/i18n.js";
import Preloader from "../src/components/Pre";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, updateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Mettre la langue par défaut en français
    i18n.changeLanguage("fr");
  }, []); // Exécuter uniquement au chargement initial

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Router>
      <Preloader load={load} />
      <div className={`App ${load ? "no-scroll" : "scroll"}`}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/project" element={<Projects />} />
          <Route path="/about" element={<About />} />
          {/* Autres routes */}
        </Routes>
        <Footer />
        <div className="language-switcher">
          <button
            className="button-cv"
            onClick={() => changeLanguage("fr")}
            style={{ marginTop: "5%" }}
          >
            Français
          </button>
          <button
            className="button-cv"
            onClick={() => changeLanguage("en")}
            style={{ marginTop: "5%" }}
          >
            English
          </button>
        </div>
      </div>
    </Router>
  );
}

export default App;
