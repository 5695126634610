import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaProjectDiagram } from "react-icons/fa";
import { GiTeamIdea } from "react-icons/gi";
import { PiMountainsFill } from "react-icons/pi";
import { GiChameleonGlyph } from "react-icons/gi";
import { FaUserClock } from "react-icons/fa";
import { TbUserUp } from "react-icons/tb";
import { useTranslation } from "react-i18next";

function Toolstack() {
  const { t } = useTranslation();

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <TbUserUp />
        <p style={{ fontSize: "35%" }}>{t("toolstack.initiative")}</p>{" "}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <GiChameleonGlyph />
        <p style={{ fontSize: "35%" }}>{t("toolstack.adaptability")}</p>{" "}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaUserClock />{" "}
        <p style={{ fontSize: "35%" }}>{t("toolstack.rigor")}</p>{" "}
      </Col>{" "}
      <Col xs={4} md={2} className="tech-icons">
        <PiMountainsFill />{" "}
        <p style={{ fontSize: "35%" }}>{t("toolstack.perseverance")}</p>{" "}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaProjectDiagram />
        <p style={{ fontSize: "35%" }}>
          {t("toolstack.projectManagement")}
        </p>{" "}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <GiTeamIdea />
        <p style={{ fontSize: "35%" }}>{t("toolstack.teamwork")}</p>{" "}
      </Col>
    </Row>
  );
}

export default Toolstack;
