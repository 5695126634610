import React from "react";
import { Col, Row } from "react-bootstrap";
import { DiAngularSimple } from "react-icons/di";
import { DiMysql } from "react-icons/di";
import { DiPhp } from "react-icons/di";
import { DiHtml5 } from "react-icons/di";
import { DiCss3 } from "react-icons/di";
import { DiJavascript1, DiNodejs, DiMongodb } from "react-icons/di";
import { SiNextdotjs } from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Row className="techstack-title"></Row>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <p style={{ fontSize: "35%" }}>Javascript</p>{" "}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiAngularSimple /> <p style={{ fontSize: "35%" }}>Angular</p>{" "}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <p style={{ fontSize: "35%" }}>Node JS</p>{" "}
      </Col>{" "}
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
        <p style={{ fontSize: "35%" }}>MongoDB</p>{" "}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMysql />
        <p style={{ fontSize: "35%" }}>MySQL</p>{" "}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPhp />
        <p style={{ fontSize: "35%" }}>PHP</p>{" "}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiHtml5 />
        <p style={{ fontSize: "35%" }}>HTML</p>{" "}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiCss3 />
        <p style={{ fontSize: "35%" }}>CSS/SCSS</p>{" "}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
        <p style={{ fontSize: "35%" }}>Next JS</p>{" "}
      </Col>
    </Row>
  );
}

export default Techstack;
