import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ReactCardFlip from "react-card-flip";

function ProjectCards(props) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setIsFlipped(!isFlipped);
  };

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
      <Card className="project-card-view" key="front" onClick={handleClick}>
        <Card.Img variant="top" src={props.imgPath} alt="card-img" />
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text style={{ textAlign: "justify" }}>
            {props.description}
          </Card.Text>
          <div className="button-project">
            {" "}
            {props.ghLink && (
              <Button variant="primary" href={props.ghLink} target="_blank">
                {props.isBlog ? "Blog" : "Voir plus"}
                &nbsp;
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>

      <Card className="project-card-view" key="back" onClick={handleClick}>
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text style={{ textAlign: "justify" }}>
            {props.descriptionBack}
          </Card.Text>

          <div style={{ fontSize: "2.5rem" }}>
            {props.devIcons.map((icon, index) => (
              <span key={index}>{icon}</span>
            ))}
          </div>

          <div className="button-project">
            {props.ghLink && (
              <Button variant="primary" href={props.ghLink} target="_blank">
                {props.isBlog ? "Blog" : "Voir moins"}
                &nbsp;
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
    </ReactCardFlip>
  );
}

export default ProjectCards;
