import React from "react";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import {
  GiBlackBook,
  GiFullMotorcycleHelmet,
  GiFilmStrip,
} from "react-icons/gi";

function AboutCard() {
  const { t } = useTranslation();

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            {t("aboutCard.descriptionPart1")}
            <span className="purple"> {t("aboutCard.etude")} </span>
            {t("aboutCard.descriptionPart2")}
            <span className="purple"> GoTEK7</span>
            {t("aboutCard.descriptionPart3")}
            <br />
            <br />
            {t("aboutCard.hobbies")}
          </p>

          <div className="icon-s">
            <ul className="about-loisirs">
              <li className="social-icons-about">
                <a
                  href="https://github.com/woorzz"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons icons-about-a"
                >
                  <GiFullMotorcycleHelmet />
                </a>
                <span>{t("aboutCard.motorcycleRides")}</span>
              </li>

              <li className="social-icons-about">
                <a
                  href="https://www.linkedin.com/in/marine-langrez-profil/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons icons-about-a"
                >
                  <GiFilmStrip />
                </a>
                <span>{t("aboutCard.movieOutings")}</span>
              </li>
              <li className="social-icons-about">
                <a
                  href="https://www.linkedin.com/in/marine-langrez-profil/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons icons-about-a"
                >
                  <GiBlackBook />
                </a>
                <span>{t("aboutCard.reading")}</span>
              </li>
            </ul>
          </div>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
