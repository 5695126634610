import React, { useState } from "react";
import { Card, Col, Row, Form, Button, Alert } from "react-bootstrap";
import { AiFillGithub } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleDownloadCV = () => {
    const cvUrl = "/CV_Marine_Langrez.pdf";
    const link = document.createElement("a");
    link.href = cvUrl;
    link.download = "CV_Marine_Langrez.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://formspree.io/f/xeqypybp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        console.error("Error sending form:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending form:", error);
    }
  };

  return (
    <Row style={{ paddingBottom: "50px" }}>
      <Col>
        <Card className="card-contact">
          <Card.Body>
            {showSuccessMessage && (
              <Alert
                variant="success"
                onClose={() => setShowSuccessMessage(false)}
                dismissible
                className="alert"
              >
                {t("contact.successMessage")}
              </Alert>
            )}
            <Row>
              <Col xs={12} md={6} className="contact-infos">
                <div style={{ textAlign: "left" }}>
                  <p className="contact-me">
                    <div className="contact-me-div">
                      {t("contact.cvMessage")}
                    </div>
                  </p>

                  <Button className="button-cv" onClick={handleDownloadCV}>
                    {t("contact.downloadCV")}
                  </Button>

                  <p className="contact-me">
                    <div className="contact-me-div">
                      {t("contact.questionMessage")}
                    </div>
                  </p>
                  <ul className="home-about-social-links">
                    <li className="social-icons">
                      <a
                        href="https://github.com/woorzz"
                        target="_blank"
                        rel="noreferrer"
                        className="icon-colour  home-social-icons"
                      >
                        <AiFillGithub />
                      </a>
                    </li>

                    <li className="social-icons">
                      <a
                        href="https://www.linkedin.com/in/marine-langrez-profil/"
                        target="_blank"
                        rel="noreferrer"
                        className="icon-colour  home-social-icons"
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <h3>{t("contact.contactForm")}</h3>
                <Form onSubmit={handleSubmit} className="form-contact">
                  <Row>
                    <Col>
                      <Form.Group controlId="formBasicName">
                        <Form.Control
                          className="input-form"
                          type="text"
                          name="name"
                          placeholder={t("contact.namePlaceholder")}
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control
                          className="input-form"
                          type="email"
                          name="email"
                          placeholder={t("contact.emailPlaceholder")}
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group controlId="formBasicMessage">
                    <Form.Control
                      className="input-form"
                      as="textarea"
                      rows={3}
                      placeholder={t("contact.messagePlaceholder")}
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    {t("contact.sendButton")}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default Contact;
