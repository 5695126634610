import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import marineImage from "../../Assets/image-marine-round.png";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  const { t } = useTranslation();

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                {t("greeting")}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>{" "}
              </h1>

              <h1 className="heading-name">
                {t("introduction")}
                <strong className="main-name"> Marine Langrez</strong>
              </h1>

              <div
                style={{
                  padding: 50,
                  textAlign: "left",
                  paddingTop: 8,
                  fontSize: 12,
                }}
              >
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }} className="div-home-img">
              <img
                src={marineImage}
                alt="home pic"
                className="img-about"
                style={{ maxHeight: "350px" }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 70 }}>
            <div className="container-arrow">
              <div className="chevron"></div>
              <div className="chevron"></div>
              <div className="chevron"></div>
            </div>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
