import React from "react";
import { Container, Row } from "react-bootstrap";
import About from "../About/About";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row></Row>
        <About />
      </Container>
    </Container>
  );
}
export default Home2;
